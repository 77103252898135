"use client"

import { LazyImage } from "@/app/_components/LazyImage"
import { MainContentLayout } from "@/app/_components/MainContentLayout"
import clsx from "clsx"
import * as styles from "./ErrorPageBody.css"

interface Props {
  statusCode: number
}

export function ErrorPageBody({ statusCode }: Props) {
  return (
    <MainContentLayout>
      <LazyImage
        className={clsx(styles.image, styles.pcImage)}
        src={
          statusCode === 404
            ? "https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/error/PC_404error@2x.png"
            : "https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/error/PC_500error@2x.png"
        }
        alt="error"
      />
      <LazyImage
        className={clsx(styles.image, styles.spImage)}
        src={
          statusCode === 404
            ? "https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/error/SP_404error@2x.png"
            : "https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/error/SP_500error@2x.png"
        }
        alt="error"
      />
    </MainContentLayout>
  )
}
